// composables/useSeo.ts
import { useHead } from "#imports";

interface SeoMetaTag {
  attributes?: {
    property?: string;
    name?: string;
    content?: string;
    rel?: string;
    href?: string;
  };
  content?: string | null;
  tag: string;
}

interface GlobalSeo {
  twitterAccount: string;
  titleSuffix: string;
  siteName: string;
  fallbackSeo: {
    twitterCard: string;
    title: string;
    description: string;
    image: {
      url: string;
    };
  };
  facebookPageUrl: string;
}

interface SiteData {
  noIndex: boolean;
  globalSeo: GlobalSeo;
  favicon: {
    url: string;
  };
  faviconMetaTags: Array<{
    attributes: Record<string, string>;
  }>;
}

interface PageSeo {
  _seoMetaTags?: SeoMetaTag[];
  title?: string;
  description?: string;
}

export function useSeo(
  pageSeo: PageSeo | null | undefined,
  siteData: SiteData | null | undefined
) {
  const route = useRoute();
  const runtimeConfig = useRuntimeConfig();

  const fullUrl = computed(() => {
    const baseUrl = runtimeConfig.public.BASE_URL || "http://localhost:3000";
    return new URL(route.fullPath, baseUrl).toString();
  });

  // Compute effective SEO data with fallbacks
  const effectiveSeoData = computed(() => {
    const fallback = siteData?.globalSeo?.fallbackSeo;

    // Get title from DatoCMS SEO tags or fallback
    const titleTag = pageSeo?._seoMetaTags?.find((tag) => tag.tag === "title");
    const ogTitleTag = pageSeo?._seoMetaTags?.find(
      (tag) => tag.attributes?.property === "og:title"
    );

    const title =
      titleTag?.content ||
      ogTitleTag?.attributes?.content ||
      pageSeo?.title ||
      fallback?.title ||
      siteData?.globalSeo?.siteName;

    // Get description from DatoCMS SEO tags or fallback
    const descriptionTag = pageSeo?._seoMetaTags?.find(
      (tag) => tag.attributes?.name === "description"
    );
    const description =
      descriptionTag?.attributes?.content ||
      pageSeo?.description ||
      fallback?.description;

    // Get image from DatoCMS SEO tags or fallback
    const ogImageTag = pageSeo?._seoMetaTags?.find(
      (tag) => tag.attributes?.property === "og:image"
    );
    const image = ogImageTag?.attributes?.content || fallback?.image?.url;

    return {
      title,
      description,
      image,
      siteName: siteData?.globalSeo?.siteName,
      titleSuffix: siteData?.globalSeo?.titleSuffix,
      twitterAccount: siteData?.globalSeo?.twitterAccount,
      facebookPageUrl: siteData?.globalSeo?.facebookPageUrl,
      twitterCard: fallback?.twitterCard || "summary_large_image",
    };
  });

  useHead(() => ({
    // Title with suffix
    title: computed(() => {
      const title = effectiveSeoData.value.title;
      const suffix = effectiveSeoData.value.titleSuffix;
      return title ? (suffix ? `${title}${suffix}` : title) : "Tranzac Club";
    }),

    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },

      // Essential meta tags
      ...(effectiveSeoData.value.description
        ? [
            {
              name: "description",
              content: effectiveSeoData.value.description,
            },
          ]
        : []),

      // OpenGraph tags
      { property: "og:url", content: fullUrl.value },
      { property: "og:type", content: "website" },
      ...(effectiveSeoData.value.title
        ? [
            {
              property: "og:title",
              content: effectiveSeoData.value.title,
            },
          ]
        : []),
      ...(effectiveSeoData.value.description
        ? [
            {
              property: "og:description",
              content: effectiveSeoData.value.description,
            },
          ]
        : []),
      ...(effectiveSeoData.value.image
        ? [
            {
              property: "og:image",
              content: effectiveSeoData.value.image,
            },
          ]
        : []),
      ...(effectiveSeoData.value.siteName
        ? [
            {
              property: "og:site_name",
              content: effectiveSeoData.value.siteName,
            },
          ]
        : []),

      // Twitter Card tags
      { name: "twitter:card", content: effectiveSeoData.value.twitterCard },
      ...(effectiveSeoData.value.twitterAccount
        ? [
            {
              name: "twitter:site",
              content: effectiveSeoData.value.twitterAccount,
            },
          ]
        : []),
      ...(effectiveSeoData.value.title
        ? [
            {
              name: "twitter:title",
              content: effectiveSeoData.value.title,
            },
          ]
        : []),
      ...(effectiveSeoData.value.description
        ? [
            {
              name: "twitter:description",
              content: effectiveSeoData.value.description,
            },
          ]
        : []),
      ...(effectiveSeoData.value.image
        ? [
            {
              name: "twitter:image",
              content: effectiveSeoData.value.image,
            },
          ]
        : []),

      // Include any additional meta tags from DatoCMS that we haven't explicitly handled
      ...(pageSeo?._seoMetaTags
        ?.filter(
          (tag) =>
            tag.tag === "meta" &&
            tag.attributes &&
            ![
              "description",
              "og:title",
              "og:description",
              "og:image",
              "twitter:title",
              "twitter:description",
              "twitter:image",
            ].includes(tag.attributes.property || tag.attributes.name || "")
        )
        .map((tag) => ({
          ...tag.attributes,
        })) || []),
    ].filter(Boolean),

    link: [
      { rel: "canonical", href: fullUrl.value },
      ...(siteData?.faviconMetaTags || []).map((tag) => ({
        ...tag.attributes,
      })),
    ],
  }));
}
